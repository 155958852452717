// --------------------------------------------------------------------------
//
//   Main
//     Imports libraries
//
//     See http://sass-guidelin.es/ for rough guidelines on editing and
//     extending these stylesheets
//
// --------------------------------------------------------------------------

@import '../node_modules/@glidejs/glide/src/assets/sass/glide.core';

// --------------------------------------------------------------------------
//   Header Comment
// --------------------------------------------------------------------------

/*!

*/

// --------------------------------------------------------------------------
//   Utilities
// --------------------------------------------------------------------------
@import 'utilities/_animations.scss';
@import 'utilities/_hiding.scss';
@import 'utilities/_media.scss';
@import 'utilities/_normalise.scss';
@import 'utilities/_positioning.scss';
@import 'utilities/_sass-easing.scss';

// --------------------------------------------------------------------------
//   Base
// --------------------------------------------------------------------------

@import 'base/variables';
@import 'base/grid';
@import 'base/fonts';
@import 'base/_fonts.scss';
@import 'base/_grid.scss';
@import 'base/_print.scss';
@import 'base/_shame.scss';
@import 'base/_typography.scss';
@import 'base/_variables.scss';

// --------------------------------------------------------------------------
//   Layout blocks / partials
// --------------------------------------------------------------------------
@import 'layout/_footer-navigation.scss';
@import 'layout/_footer.scss';
@import 'layout/_header.scss';
@import 'layout/_layout.scss';
@import 'layout/_primary-navigation.scss';
@import 'layout/_secondary-navigation.scss';
@import 'layout/_sections.scss';
@import 'layout/_title.scss';

// --------------------------------------------------------------------------
//   Components
// --------------------------------------------------------------------------
@import 'components/_buttons.scss';
@import 'components/_gallery.scss';
@import 'components/_images.scss';
@import 'components/_slider.scss';
@import 'components/_tease-clinic.scss';
@import 'components/_tease-post.scss';
@import 'components/_tease.scss';

// --------------------------------------------------------------------------
//   Modules
// --------------------------------------------------------------------------
@import 'modules/_forms.scss';
@import 'modules/_reveal.scss';

// --------------------------------------------------------------------------
//   Pages
// --------------------------------------------------------------------------
@import 'pages/_home.scss';
@import 'pages/_post.scss';
@import 'pages/_posts.scss';
@import 'pages/_search.scss';
@import 'pages/_staff.scss';
@import 'pages/_standard.scss';

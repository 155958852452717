// --------------------------------------------------------------------------
//
//  Layout
//    Page wrappers and body/html
//
// --------------------------------------------------------------------------

html {
    background-color: $white;
    overflow-x: hidden;

    &.no-touch {
        overflow-y: auto;
    }
}

body {
    width: 100%;
    height: 100%;
}

// --------------------------------------------------------------------------
//   Page Wrapper
// --------------------------------------------------------------------------

.page-wrapper {
    position: relative;
    width: 100%;
}

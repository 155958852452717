.search-content {
    min-height: 40vmin;
}

.searchform {
    display: flex;
    justify-content: center;
    max-width: 600px;
    margin: 5vmin auto 0;
    gap: 3vmin;
    padding: 5vmin 0;
}

.searchform--input {
    max-width: 80%;
}

.searchform--submit {
}

div.search-results {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 10vmin;
}

.search-result {
    padding: 3vmin 0;
    border-bottom: 1px solid #898888;
}

.search-result--title {
    margin: 0;
}
.search-result--parent {
    color: $dark-gray;
    font-size: 14px;
}

// --------------------------------------------------------------------------
//
//  Type
//    Default typographic styles and elements
//
// --------------------------------------------------------------------------

body {
    font-family: $font-family;
    font-size: 18px;
    font-weight: 300;
    font-size-adjust: auto;
    line-height: 1.4;

    text-align: left;
    word-wrap: break-word;

    color: $base-body-color;

    text-rendering: optimizeLegibility;

    @include media($tablet-down) {
        font-size: 16px;
    }
}

// --------------------------------------------------------------------------
//   Headings
// --------------------------------------------------------------------------

h1 + h2,
h2 + h3,
h3 + h4,
h5 + h6 {
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $base-heading-color;
    font-family: $font-family;
    font-weight: 300;
    line-height: 1.15;

    &:first-child {
        margin-top: 0;
    }

    .post-content--container & {
        font-weight: 300;
    }
}

h1 {
    font-size: 4.3vmin;

    #tinymce & {
        font-size: 32px;
    }
}
h2 {
    font-size: 3.5vmin;

    #tinymce & {
        font-size: 26px;
    }
}
h3 {
    font-size: 2.5vmin;

    #tinymce & {
        font-size: 20px;
    }
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 16px;
}
h6 {
    font-size: 14px;
}

@include media($tablet-down) {
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 14px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 14px;
    }
}

// --------------------------------------------------------------------------
//   Content Elements
// --------------------------------------------------------------------------

a {
    color: $base-link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $base-link-hover;
    }
}

p {
    margin-top: 0;
    margin-bottom: 20px;
}

figure {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}

hr {
    display: block;
    margin: 0;
    border: 0;
    border-top: 1px solid $black;
    background-color: $black;
}

blockquote {
    margin: 0 0;
    padding: 0;
}

ins {
    background: $yellow;
    color: $black;
    text-decoration: none;
}

mark {
    background: $yellow;
    color: $black;
    font-style: italic;
    font-weight: bold;
}

table {
    width: 100%;
}

.post-list {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: start;
    justify-items: start;
    gap: 7vmin 2%;
    margin-top: 4vmin;
    margin-bottom: 6vmin;

    @include media($tablet-down) {
        grid-template-columns: 1fr;
    }
}

.pagination {
    margin-top: 6vmin;
    margin-bottom: 6vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pagination--button {
    @include button;
}

// --------------------------------------------------------------------------
//
//  Buttons
//
// --------------------------------------------------------------------------

@mixin button($primary: $base-link-color, $content: $white) {
    display: inline-flex;
    gap: 10px;
    padding: 10px 15px;
    background-color: transparent;
    border-radius: 0;
    outline: 0;
    cursor: pointer;
    user-select: none;

    color: $black;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-family: $font-family;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 1.5px;

    transition: all ease-in-out 300ms;
    will-change: border-color, color;

    svg {
        transition: all ease-in-out 300ms;
        max-width: 25px;
        max-height: 25px;
        width: 100%;
        height: 100%;
    }

    &:hover {
        outline: 0;
        background: none;
        border-color: $mute-pink;
        color: $mute-pink;

        svg {
            fill: $mute-pink;
        }
    }

    &:active,
    &.active {
        outline: 0;
    }

    &.disabled,
    &[disabled] {
        background-image: none;
        opacity: 0.4;
        cursor: not-allowed;
        box-shadow: none;
    }

    &.button-icon-down {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &.button-icon-right {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        svg {
            order: 1;
        }
    }

    &.button-icon-left {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        svg {
            order: 0;
        }
    }
}

// --------------------------------------------------------------------------
//
//  Forms
//    Form elements, plugin styling
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Variables
// --------------------------------------------------------------------------

$form-element-border: 1px solid #898888;

// --------------------------------------------------------------------------
//   Generic form elements
// --------------------------------------------------------------------------

form {
    // ----- Remove default styling ----- //

    ul,
    ol,
    li {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    label {
        font-weight: 600;
    }
}

// ----- Buttons ----- //

button,
[type='button'],
[type='submit'],
[type='reset'] {
    @include button;
    border: 1px solid $black;
    padding: 18px 22px;
}

// ----- Input types ----- //

[type='color'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
textarea,
select {
    max-width: 100%;
    width: 100%;
    border: none;
    border-bottom: $form-element-border;
    padding: 5px 10px;
    font-weight: normal;

    &:hover {
        background: $white;
    }

    &:focus {
        background: $white;
    }
}

[type='checkbox'],
[type='radio'] {
    margin-right: 10px;
    accent-color: $black;
}

.block {
    .acf-fields > .acf-field {
        border-top: none;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 2vmin;

        input,
        textarea {
            font-size: 18px;
            font-weight: 300;
            padding-bottom: 12px;
            padding-left: 0;
            color: $black;

            &::placeholder {
                color: $black;
                opacity: 1;
            }
        }

        .message {
            font-weight: 300;
            font-size: 14px;
        }
    }

    .acf-form-submit {
        margin-top: 5vmin;
    }
}

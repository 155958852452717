// --------------------------------------------------------------------------
//
//   Primary Navigation
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Menu
// --------------------------------------------------------------------------

.navigation-toggle {
    display: none;
    position: relative;
    padding: 8px;

    @include media($mobile-down) {
        padding: 0px;
    }

    cursor: pointer;

    div {
        position: relative;
        width: 40px;
        height: 13px;
    }

    span {
        display: block;
        width: 40px;
        height: 1px;
        background: black;
        position: absolute;

        &.top {
            top: 0px;
        }
        &.bottom {
            top: 12px;
        }
    }

    &.navigation-toggle__open {
        span {
            background-color: $white;

            &.top {
                top: 6px;
                transform: rotate(45deg);
            }
            &.bottom {
                top: 6px;
                transform: rotate(-45deg);
            }
        }
    }
}

@include media($large-desktop-down) {
    .navigation__open {
        .primary-navigation {
            transform: translateX(0);
        }
        .primary-navigation-backdrop {
            opacity: 1;
            pointer-events: all;
        }
    }

    .primary-navigation-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .navigation-toggle {
        display: block;
        order: 0;
    }

    .primary-navigation-backdrop {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100vw;
        height: 100vh;

        z-index: 50;
        pointer-events: none;
        transition-delay: 30ms;
        transition: opacity 300ms ease-in-out;
        opacity: 0;
    }

    .primary-navigation {
        position: absolute;
        left: 0;
        top: 0;
        width: 75%;
        height: 100vh;
        max-width: 400px;

        font-family: $font-family;

        background-color: $dark-pink;

        transform: translateX(-100%);
        transition: all ease-in-out 0.5s;

        z-index: 100;

        overflow-y: scroll;

        .primary-navigation__desktop {
            display: none;
        }

        .primary-navigation__mobile {
            display: block;
        }

        .mobile-booking-link {
            display: inline-block;
            flex-grow: 0;
            border: 2px solid $white;
            padding: 10px 15px;

            text-transform: uppercase;
            color: $white;
        }

        .menu--level-one {
            display: flex;
            flex-direction: column;
            justify-items: flex-start;
            margin: 0;
            padding: 0;
            overflow-y: scroll;

            background-color: #e8dcd7;
        }

        .menu-item-has-children > a::after {
            display: flex;
            content: '+';

            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 40px;
            padding-bottom: 6px;

            align-items: center;
            justify-items: center;

            font-size: 34px;
            font-weight: 300;

            pointer-events: none;
        }

        .menu-item--open > a::after {
            content: '–';
        }

        .menu-item {
            display: block;
            list-style-type: none;
            position: relative;

            a {
                display: block;
                position: relative;
                transition: all ease 0.3s;
                will-change: color;
                white-space: nowrap;

                font-size: 18px;

                padding: 18px 22px;
                border-bottom: 1px solid $dark-gray;
            }

            &.menu-item__nofocus > a {
                color: #4e4f4d;
            }

            &:hover {
                > a {
                    color: $mute-pink;
                }
            }

            > .menu {
                overflow: hidden;
                padding: 0;
                margin: 0;

                transform-origin: 0;
            }

            &.menu-item--open {
                > .menu {
                }
            }
        }

        .menu--level-one {
            > .menu-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                > a {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .menu--level-two {
            > .menu-item {
                display: block;
                position: relative;

                > a {
                    display: block;
                    position: relative;
                    padding-left: 2.5rem;
                    font-size: 16px;
                }
            }
        }

        .menu--level-three {
            display: flex;
            flex-direction: column;
            justify-items: flex-start;

            .menu-item {
                > a {
                    padding-left: 3.5rem;
                    font-size: 16px;
                }
            }
        }
    }
}

@include media($large-desktop-up) {
    .primary-navigation {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        margin: 0;
        padding-top: 8px;
        margin-right: 60px;

        pointer-events: all;

        font-family: $font-family;

        .mobile-booking-link {
            display: none;
        }

        .primary-navigation__desktop {
            display: block;
            height: 100%;
        }

        .primary-navigation__mobile {
            display: none;
        }

        .menu--level-one {
            display: flex;
            margin: 0;
            padding: 0;
            justify-content: space-between;
        }

        .menu-item {
            display: inline-block;
            list-style-type: none;

            a {
                display: block;
                position: relative;
                transition: all ease 0.3s;
                will-change: color;
                white-space: nowrap;
            }

            &.menu-item__nofocus > a {
                color: #4e4f4d;
            }

            &:hover,
            &.current-menu-item {
                > a {
                    color: $mute-pink;
                }
            }

            > .menu {
                opacity: 0;
                transition: all 350ms ease-in-out;
                pointer-events: none;
                transform: translateY(-20px);
            }

            &.menu-item--open {
                > .menu {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }
            }
        }

        .menu--level-one {
            height: 100%;

            > .menu-item {
                display: flex;
                flex-direction: column;
                justify-content: center;

                > a {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                }
            }
        }

        .menu--level-two {
            position: absolute;
            top: 85px;
            flex-direction: column;
            margin: 0;
            padding: 40px 0 60px 0;
            background: $light-gray;
            width: auto;

            &::before {
                content: '';
                display: block;
                background: $light-gray;
                width: 300vw;
                height: 100%;
                top: 0;
                left: -100vw;
                position: absolute;
                z-index: 100;
            }

            > .menu-item {
                display: block;
                z-index: 120;
                position: relative;
                padding: 0 0 10px 0;

                > a {
                    display: inline-block;
                    position: relative;
                    z-index: 130;
                    font-size: 18px;
                }
            }
        }

        .menu--level-three {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 100%;
            top: 0;
            padding-left: 2rem;
            padding: 0 0 30px 60px;

            &::after {
                content: '';
                display: block;
                background: $light-gray;
                width: 200vw;
                height: 100%;
                top: 0;
                left: -100vw;
                position: absolute;
                z-index: 90;
            }

            .menu-item {
                z-index: 130;
                font-size: 16px;
                line-height: 1.6;
            }
        }
    }
}

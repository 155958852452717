.reveal {
    transition: all ease-in-out 1200ms;

    opacity: 0;
    transform: translateY(20%);

    &.revealed {
        opacity: 1;
        transform: translateY(0);
    }
}

// --------------------------------------------------------------------------
//
//   Fonts
//
// --------------------------------------------------------------------------

@font-face {
    font-family: 'ARS Maquette Pro';
    src: url('assets/fonts/ARSMaquettePro-Light.woff2') format('woff2'),
        url('assets/fonts/ARSMaquettePro-Light.woff') format('woff'),
        url('assets/fonts/ARSMaquettePro-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'ARS Maquette Pro';
    src: url('assets/fonts/ARSMaquettePro-Regular.woff2') format('woff2'),
        url('assets/fonts/ARSMaquettePro-Regular.woff') format('woff'),
        url('assets/fonts/ARSMaquettePro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'ARS Maquette Pro';
    src: url('assets/fonts/ARSMaquettePro-Medium.woff2') format('woff2'),
        url('assets/fonts/ARSMaquettePro-Medium.woff') format('woff'),
        url('assets/fonts/ARSMaquettePro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ARS Maquette Pro';
    src: url('assets/fonts/ARSMaquettePro-Bold.woff2') format('woff2'),
        url('assets/fonts/ARSMaquettePro-Bold.woff') format('woff'),
        url('assets/fonts/ARSMaquettePro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

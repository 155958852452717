// --------------------------------------------------------------------------
//
//  Footer
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//
//  Upper Footer
//
// --------------------------------------------------------------------------

#sticky-footer {
    background-color: $dark-pink;
    color: $white;
    padding: 30px 0;

    @include media($tablet-down) {
        padding: 20px;
        position: sticky;
        bottom: 0;
        z-index: 200;
    }
}

.sticky-footer--container {
    display: flex;
    justify-content: center;

    .book-an-appointment {
        color: $white;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.5px;

        border-left: 1px solid white;
        border-right: 1px solid white;

        padding: 12px 45px;
        transition: all 200ms ease-in-out;

        &:hover {
            color: $charcoal;
            border-color: $charcoal;
        }

        @include media($tablet-down) {
            padding: 6px 15px;
            border: 1px solid $white;

            font-size: 16px;
        }
    }
}

#footer {
    background-color: $charcoal;
    padding: 70px 0;
}

.footer--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    @include media($tablet-down) {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.footer-information {
    flex-basis: 30%;

    color: $light-pink;

    .footer-logo {
        display: block;
        max-width: 270px;
        margin-bottom: 35px;

        @include media($tablet-down) {
            display: none;
        }
    }
}

.footer-affiliates {
    display: flex;
    justify-content: flex-start;
    gap: 1.5vmin;
}

.footer-contact {
    margin-bottom: 40px;
    font-size: 21px;
    line-height: 1.2;
}

.footer-social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 60px;

    a {
        display: block;
        padding-right: 10px;
    }
}

// --------------------------------------------------------------------------
//
//   Secondary Navigation
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Menu
// --------------------------------------------------------------------------

.secondary-navigation {
    background-color: $charcoal;
    justify-content: center;

    .home & {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 50;
        background-color: transparent;
    }

    @include media($large-desktop-down) {
        display: none;
    }

    .secondary-navigation--container {
        @include center;
        max-width: 1160px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        margin: 0;
        padding: 0 1rem 0 0;
        height: 100px;
    }

    .secondary-navigation-actions {
        .booking-link {
            display: block;
            padding: 10px 15px;
            background-color: $mute-pink;
            color: $white;
            transition: all ease 0.3s;
            white-space: nowrap;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 1.5px;

            &:hover {
                background-color: $dark-pink;
            }
        }
    }

    .menu--level-one {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        padding: 0;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-right: 8vmin;
        gap: 2vmin 4vmin;

        &::after {
            content: '';
            display: block;
            background: rgba($charcoal, 0.5);
            transition: all ease 0.3s;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 90;
            opacity: 0;
            pointer-events: none;
        }

        &.has-open-item,
        &:hover {
            &::after,
            &::before {
                opacity: 1;
            }
        }

        @include media($large-desktop-down) {
            margin-right: 5vmin;
        }

        > .menu-item {
            display: block;
            z-index: 95;

            @include media($tablet-down) {
                margin-right: 0px;
            }

            > a {
                display: block;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                color: $white;
            }

            &:hover,
            &.current-menu-item {
                > a {
                    color: $medium-pink;
                }
            }
        }
    }

    .menu-item {
        display: inline-block;
        list-style-type: none;

        a {
            display: block;
            position: relative;
            transition: all ease 0.3s;
            will-change: color;
            white-space: nowrap;
        }

        > .menu {
            opacity: 0;
            transition: all 350ms ease-in-out;
            pointer-events: none;
            transform: translateY(-20px);
        }

        &.menu-item--open {
            > .menu {
                opacity: 1;
                pointer-events: all;
                transform: translateY(0);
            }
        }
    }

    .menu--level-two {
        position: absolute;
        top: 100px;
        flex-direction: column;
        margin: 0;
        padding: 40px 0 60px 0;
        width: auto;
        z-index: 100;

        &::before {
            content: '';
            display: block;
            background-color: $light-gray;
            width: 300vw;
            height: calc(200vh);
            top: 0;
            left: -150vw;
            position: absolute;
            z-index: 95;
            transition: all ease 0.7s;
            // pointer-events: none;
        }

        > .menu-item {
            display: block;
            z-index: 120;
            position: relative;
            padding: 0 0 8px 0;
            color: #4e4f4d;

            > a {
                color: #4e4f4d;
                display: inline-block;
                position: relative;
                z-index: 130;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                    color: $medium-pink;
                }
            }
        }
    }

    .menu--level-three {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 100%;
        top: 0;
        padding-left: 2rem;
        padding: 0 0 30px 60px;

        .menu-item {
            z-index: 130;
            font-size: 16px;
            line-height: 1.6;

            > a {
                color: #4e4f4d;
                font-weight: 400;
            }

            &:hover > a {
                color: $medium-pink;
            }
        }
    }
}

// --------------------------------------------------------------------------
//
//   Variables
//
// --------------------------------------------------------------------------

// ----- Colours ----- //

$black: #1a1919;
$dark-charcoal: #4e4c4c;
$charcoal: #3c3838;
$light-charcoal: #8a8b8a;
$dark-gray: #b6b0ab;
$gray: #dadada;
$light-gray: #f6f6f6;
$off-white: #f1f1f1;
$white: #fffffe;
$light-pink: #e6caca;
$mute-pink: #c68b95;
$medium-pink: #c19e9c;
$dark-pink: #a1797b;
$gold: #b69643;

$blue: #00addd;
$green: #9c9c48;
$yellow: #fff005;
$red: #d78f8e;

// ----- Typography ----- //

$base-body-color: $black;
$base-heading-color: $black;

$base-link-color: $black;
$base-link-hover: lighten($base-link-color, 20%);

$base-border-color: $gray;
$base-border-radius: 2px;

$font-family: 'ARS Maquette Pro', 'Helvetica Neue', Arial, Helvetica;

// ----- Transitions ----- //

$navigation-transition: all 500ms $ease-in-out-quad;

// ----- Default grid settings ----- //

$max-width: 1200px;
$grid-columns: 12;
$gap: 20px;
$default-feature: max-width;

// ----- Define breakpoints ----- //

$large-desktop: 1200px;
$desktop: 880px;
$tablet: 680px;
$mobile: 380px;

$large-desktop-down: max-width $large-desktop;
$desktop-down: max-width $desktop;
$tablet-down: max-width $tablet;
$mobile-down: max-width $mobile;
$small-mobile-down: max-width 580px;

$large-desktop-up: min-width $large-desktop;
$desktop-up: min-width $desktop;
$tablet-up: min-width $tablet;
$mobile-up: min-width $mobile;

$x: 100px;
$y: 86px;
$z: 42px;

:root {
    --header-height: 85px;
    --margin-x: #{$x};
    --margin-y: #{$y};
    --margin-z: #{$z};

    @include media($tablet-down) {
        --margin-x: #{$x * 0.75};
        --margin-y: #{$y * 0.75};
        --margin-z: #{$z * 0.75};
    }

    @include media($mobile-down) {
        --margin-x: #{$x * 0.5};
        --margin-y: #{$y * 0.5};
        --margin-z: #{$z * 0.5};
    }

    @include media($large-desktop-down) {
        --header-height: 60px;
    }
}

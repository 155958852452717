// --------------------------------------------------------------------------
//
//  Animation Mixins
//
// --------------------------------------------------------------------------

@mixin nth-delay($delay: 200ms) {
    @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
            animation-delay: (0.2s * $i);
        }
    }
}

html {
    scroll-behavior: smooth;
}

// --------------------------------------------------------------------------
//
//  Header
//
// --------------------------------------------------------------------------

.wrapper.header-wrapper {
    z-index: 320;

    background-color: $white;
    margin-top: 25px;
    margin-bottom: 25px;

    position: sticky;
    top: 0;

    @include media($large-desktop-down) {
        margin-top: 0;
        margin-bottom: 0;
    }
    &.stuck {
        box-shadow: 0 0 3px rgba(black, 0.3);

        .header-container {
            // height: 85px;

            @include media($large-desktop-down) {
                height: 60px;
            }
        }
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        z-index: 350;
        height: var(--header-height);
        padding: 0 1rem;

        @include media($large-desktop-up) {
        }

        transition: all ease-in-out 0.3s;
    }

    .logo {
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: 250px;
        width: 100%;
        order: 1;
        margin-bottom: 0;
        margin-right: 7vmin;

        .logo-link {
            display: block;

            img {
                display: block;
            }
        }

        @include media($large-desktop-down) {
            max-width: 200px;
            margin-right: 0;
            order: 2;
            height: auto;
        }
    }

    .search-toggle {
        display: block;
        align-self: center;
        margin-right: 60px;
        padding-top: 8px;
        order: 2;

        a {
            display: block;

            svg {
                display: block;
            }
        }

        @include media($large-desktop-down) {
            display: none;
        }
    }

    .shop-link {
        align-self: center;
        white-space: nowrap;
        padding-top: 8px;
        margin-right: 20px;
        order: 3;
        content: '';
        font-size: 16px;

        .shop-link--icon {
            display: none;
            width: 30px;
            height: 30px;
        }

        .shop-link--text {
            strong {
                font-weight: 600;
            }
        }

        @include media($large-desktop-down) {
            padding-top: 0;
            order: 2;

            .shop-link--text {
                display: none;
            }
            .shop-link--icon {
                display: block;
            }
        }

        @include media($tablet-down) {
            margin-right: 0;
        }
    }

    .primary-navigation {
        order: 2;

        @include media($large-desktop-down) {
            order: 1;
        }
    }

    .navigation-toggle {
        order: 2;

        @include media($large-desktop-down) {
            order: 1;
        }
    }
}

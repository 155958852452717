// --------------------------------------------------------------------------
//
//   Grid
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Initialise media query order
// --------------------------------------------------------------------------

@include media($large-desktop-down) {
    /* */
}
@include media($desktop-down) {
    /* */
}
@include media($tablet-down) {
    /* */
}
@include media($mobile-down) {
    /* */
}
@include media($small-mobile-down) {
    /* */
}

@include media($mobile-up) {
    /* */
}
@include media($tablet-up) {
    /* */
}
@include media($desktop-up) {
    /* */
}
@include media($large-desktop-up) {
    /* */
}

// --------------------------------------------------------------------------
//   Centering mixin for containers
// --------------------------------------------------------------------------

@mixin container {
    position: relative;
    display: flex;
    justify-content: center;
}

@mixin center {
    width: 100%;
    max-width: $max-width;

    @include media($large-desktop-up) {
    }

    @include media($desktop-up) {
    }

    @include media($tablet-up) {
    }
}

@mixin inner-center {
    width: 100%;
    max-width: $max-width;
    padding: 0 15px;

    @include media($large-desktop-up) {
        padding: 0 50px;
    }

    @include media($desktop-up) {
        padding: 0 30px;
    }

    @include media($tablet-up) {
        padding: 0 20px;
    }
}

.single--content {
    @include inner-center;

    .wp-container-1 {
        .wp-block-post-date {
            padding-right: 26px;
            border-right: 1px solid $black;
        }
    }
}

.single--title {
    font-size: 3vmin;
    margin-bottom: 4vmin;
}

.single--list {
    @include inner-center;
    margin-bottom: 6vmin;
}

.list-header {
    margin-top: 8vmin;
    margin-bottom: 3vmin;
}

.tease {
    flex-basis: 30%;
    padding-top: 3vmin;

    @include media($tablet-down) {
        margin-right: 0;
    }

    a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &:hover {
        .tease--thumbnail {
            opacity: 0.7;
        }
    }
}

.tease--title {
    font-size: 18px;
    font-weight: 500;
}

.tease--meta {
    color: $mute-pink;
    margin-bottom: 3vmin;
    flex-basis: 3rem;
    font-size: 16px;
    font-weight: 500;

    @include media($tablet-down) {
        flex-basis: auto;
    }
}

.tease--thumbnail {
    transition: all ease-in-out 500ms;
    justify-self: flex-end;
}

.tease-clinic {
    border-top: 1px solid $black;
    padding-top: 3vmin;
    position: relative;

    &::after {
        display: block;
        position: absolute;
        z-index: 100;
        content: '';
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        width: 100%;
        height: 5vmin;
        bottom: 0vmin;
    }

    &::before {
        position: absolute;
        left: 0;
        bottom: calc(5vmin - 7px);
        z-index: 100;
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAxCAMAAACyCzS2AAAAdVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////li2ZAAAAAJnRSTlMAbfm2FDQP/NKtpVZAOzAnBfLu4NnHwLyilZF4dGhhRB3EsIh8UHuj71oAAAC7SURBVEjHpczFAcMwAATBCMwYNtuh67/EPPXXTgFz8ieqw8OOB4OHEQ93PNieDkrwMOPhgYcspYMqPBR4sCkdZPCQ40E7HhwelODB4UE1HnI8yODhfNBBDg8yeLAtHXT1dNCjp4NKPGjCgz540IgHDT0d9PJ00DWhg6yhg+QOOui80kG6N3SQio4OyoYudgjeNR2k2+LhIFm3+cghyJ5LGzsEl8I0aewQ3MpvlaSxQ3DJn+U0r1v1q5v2Dz8YYfI+rH0RAAAAAElFTkSuQmCC');
    }
}

// --------------------------------------------------------------------------
//
//  Sections
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Wrapper
// --------------------------------------------------------------------------

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

// --------------------------------------------------------------------------
//   Container / Centering
// --------------------------------------------------------------------------

.container {
    @include center;
    padding: 0 $gap;
    flex-wrap: wrap;

    @include media($mobile-down) {
        padding: 0 10px;
    }
}

.inner-container {
    @include inner-center;

    @include media($mobile-down) {
        padding: 0 10px;
    }
}

.blocks {
    overflow: hidden;

    hr.wp-block-separator {
        border-bottom: 1px solid $black;
        margin: 4vmin 0;
    }

    .wp-block-columns {
        margin-bottom: 0;
        gap: 1vmin !important;

        &.mobile-gap {
            @include media($desktop-down) {
                gap: 30px !important;
            }
        }

        &.vertical-center {
            .wp-block-column {
                justify-content: center;
            }
        }

        &.is-not-stacked-on-mobile {
            flex-wrap: wrap !important;

            & > .wp-block-column {
                @include media($desktop-down) {
                    flex-basis: 49% !important;
                }
            }
        }
    }

    .wp-block-column {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .vertical-center & {
        }
    }

    .wp-block-group {
        height: 100%;
    }

    .wp-block-image {
        margin-bottom: 0 !important;
    }
}

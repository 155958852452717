// --------------------------------------------------------------------------
//
//   Footer Navigation
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
//   Menu
// --------------------------------------------------------------------------

.footer-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;

    pointer-events: all;

    font-family: $font-family;

    .mobile-booking-link {
        display: none;
    }

    .menu--level-one {
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-right: 60px;

        @include media($tablet-down) {
            flex-direction: column;
        }

        > .menu-item {
            display: block;
            margin-bottom: 40px;
            margin-right: 60px;

            @include media($tablet-down) {
                margin-right: 0px;
            }

            > a {
                display: block;
                align-items: center;
                font-size: 20px;
                letter-spacing: 1.5px;

                text-transform: uppercase;
            }
        }
    }

    .menu-item {
        display: inline-block;
        list-style-type: none;

        a {
            display: block;
            position: relative;
            transition: all ease 0.3s;
            will-change: color;
            white-space: nowrap;
            color: $white;
        }
    }

    .menu--level-two {
        flex-direction: column;
        margin: 0;
        padding: 0;
        margin-top: 20px;

        > .menu-item {
            display: block;
            position: relative;

            > a {
                display: block;
                position: relative;
                font-size: 16px;
                color: rgba($white, 0.8);
                font-weight: 400;

                padding: 5px 0;
            }

            &:hover,
            &.current-menu-item {
                > a {
                    color: $mute-pink;
                }
            }
        }
    }
}

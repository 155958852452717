// --------------------------------------------------------------------------
//
//  Shame
//    Bad practice, browser hacks and quick fixes
//    Keep definitions in here for a to-do list to re-factor / replace later
//
// --------------------------------------------------------------------------

html body #wpadminbar {
    // top: -46px;
    // display: none !important;
}

@include media($mobile-down) {
}

html :where(.wp-block) {
}

.wp-block-separator {
    border-width: 1px !important;
    border-bottom: none !important;
    margin-top: 2vmin;
}
